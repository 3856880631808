<!-- eslint-disable vue/multiline-html-element-content-newline -->
<template>
  <component
    :is="elementType"
    v-bind="linkProps"
    :class="['komodo-link', size, { 'non-navigational': nonNavigational }, { 'non-bolded': nonBolded }, { disabled: disabled }]"
    @click="$emit('click', $event)"
    @click.right="$emit('contextmenu', $event)"
  ><slot>{{ text }}</slot></component>
</template>
<!-- eslint-enable vue/multiline-html-element-content-newline -->

<script lang="ts">
import { computed, PropType, defineComponent } from 'vue'
import { Size } from 'shared/lib/component_size.ts'

export { Size } from 'shared/lib/component_size.ts'

export default defineComponent({
  name: 'KomodoLink',
  props: {
    text: {
      type: String,
      default: undefined
    },
    to: {
      type: [String, Object] as PropType<string | { name: string; params: object } | Location>,
      default: undefined
    },
    href: {
      type: String,
      default: undefined
    },
    size: {
      type: String as PropType<Size>,
      default: Size.Medium
    },
    nonNavigational: {
      type: Boolean,
      default: false
    },
    nonBolded: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    target: {
      type: String as PropType<string | null>,
      default: null
    }
  },
  emits: {
    click: (value: HTMLHyperlinkElementUtils) => value,
    contextmenu: (value: HTMLHyperlinkElementUtils) => value
  },
  setup (props) {
    const elementType = computed(() => {
      if (props.href || props.nonNavigational) {
        return 'a'
      } else {
        if (!props.to) {
          console.error('\'to\' is required if not using href or nonNavigational')
          return 'a'
        }
        return 'router-link'
      }
    })

    // it turns out that, if you provide router-link
    // with both a :to and an :href, even if the href
    // is undefined / null, you can't do things like
    // _target=blank
    const linkProps = computed(() => {
      const newProps = Object()
      if (props.to) newProps.to = props.to
      else newProps.href = props.href
      if (props.target) newProps.target = props.target
      return newProps
    })

    return {
      elementType,
      linkProps
    }
  }
})
</script>

<style lang="scss" scoped>
.komodo-link {
  @include color-action-blue-base;

  text-decoration: underline;

  &.extra-small {
    @include font-action-a3-action-small-compact;
  }

  &.small {
    @include font-subtitle-subtitle-2;
  }

  &.medium {
    @include font-action-a1-action-baseline;
  }

  &.large {
    @include font-action-a4-action-large;
  }

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}

.komodo-link.non-navigational {
  text-decoration: none;
}

.komodo-link.non-bolded {
  font-weight: 400;
}

.komodo-link.disabled {
  @include color-gray-3-60;

  pointer-events: none;
}
</style>
